<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import moment from 'moment'
  import axios from "axios";
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
    page: {title: "EINSTUDIO-Homework",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader, vueDropzone: vue2Dropzone
    },data() {
      return {
        title: "HOMEWORKS",
        items: [{text: "Home",href:"/"}, {text: "My Homeworks",active: true}],
        baseUrl:test.baseUrl,fileUrl:test.fileUrl,user:[],files:[],groupId:"",uploadedFiles: [],myGorups:[],deliveryDate: null,
        dropzoneOptions: {
          url: "https://httpbin.org/post",
          thumbnailWidth: 200,
          maxFilesize: 25,
          timeout: 180000,
          parallelUploads: 100,
          addRemoveLinks: true,
          acceptedFiles: ".img,.jpg,.png,.gif,.pptx,.pdf,.xlsx,.docx",
          accept: this.checkDupl
        },explanation:"",id:"",name:"",link:"",videoLink:"",homeworkimageUrl:"",detail:[],remiders:[],reminderId:""
      };
      },created:function(){
        this.user = JSON.parse(localStorage.getItem('user'));
        this.loadData();
      },methods: {
         addData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("add-file-form"));

         formToPost.append("userId",this.user.id)
         formToPost.append("groupId",this.groupId)
         formToPost.append("reminderId",this.reminderId)
         formToPost.append("status",true)
         formToPost.append("isInGroup",true)

         for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formToPost.append("files",element)
         }
         axios.post(`${this.baseUrl}/Homework/Add`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
          this.hideModal('modal-lg');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<p>"+ error.response.data[index].message+"</p>";
            }
          }
          else
          {
            html ="<p>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },editData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("edit-file-form"));

         formToPost.append("userId",this.user.id)
         formToPost.append("groupId",this.groupId)
         formToPost.append("status",true)
         formToPost.append("isInGroup",true)
         formToPost.append("reminderId",this.reminderId)
         formToPost.append("id",this.id)

         for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formToPost.append("files",element)
         }
         axios.put(`${this.baseUrl}/Homework/Set`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.hideModal('modal-edit');
          this.initialData(this.id);
          this.loadData();
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },loadData(){
          let loader=this.$loading.show();
          var urls= this.baseUrl+"/Homework/GetAll?status=true";
          if(this.user.rol!='admin')
          urls=(urls+"&userId="+this.user.id)

          axios.get(urls,{
            headers:{Authorization: `Bearer ${this.user.jwt}` }
          }).then((response)=>{
             this.files=response.data;
             loader.hide();
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },dropzoneLoadedFile(file) {
        this.uploadedFiles.push(file);
      },checkDupl(file, done) {
        let v = this.$refs.FileDropzone;
        let files = v.getAcceptedFiles()
        let item = files.find( el => el.upload.filename === file.name )
        if (item) {
          done("duplicate")
        } else {
          done()
        }
      },format_date(value)
      {
         if (value) 
         {
           return moment(String(value)).format('MM.DD.YYYY')
          }
      },notBeforeToday(date) {
         return date < new Date(new Date().setHours(0, 0, 0, 0));
      },getMyGroups(){

        let url= this.baseUrl+"/Group/GetAllWithoutInclude?status=true";
        if(this.user.rol!='admin'){
          url= url+"&groupUserId="+this.user.id;
        }
          axios.get(url,{
            headers:{Authorization: `Bearer ${this.user.jwt}` }
          }).then((response)=>{
            this.myGorups=response.data;
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },selectedGroup(ev){
       
        var elem=  document.getElementById("reminderId")
        if(ev.target.value!=' ')
        {
          
          this.groupId=ev.target.value
         this.getGroupsReminders()
         elem.removeAttribute("disabled");
     
   
        }
        else{
          elem.addAttribute("disabled");
        }

      },getGroupsReminders(){
          axios.get(this.baseUrl+"/Reminder/GetList?status=true&groupId="+this.groupId,{
            headers:{Authorization: `Bearer ${this.user.jwt}` }
          }).then((response)=>{
            this.remiders=response.data;
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },initialData(id){
         // let loader= this.$loading.show();
          axios.get(`${this.baseUrl}/Homework/GetById/`+id, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            this.getMyGroups();
            this.getGroupsReminders();
            this.name= response.data.name;
            this.explanation=response.data.explanation;
            if(response.data.coverImage!=null){
              this.homeworkimageUrl= this.fileUrl+'/files/images/homework/'+response.data.basePath+'/'+response.data.subPath+'/'+response.data.coverImage;
            }
            else{
              this.homeworkimageUrl=null;
            }
            
            this.videoLink= response.data.videoLink;
            //this.uploadedFiles= response.data.homeworkFiles;
            this.groupId=response.data.groupId;
            this.link=response.data.link;
            this.detail=response.data.homeworkFiles;
            this.id=response.data.id;
           this.reminderId= response.data.reminderId;

           // loader.hide();
          })
          .catch((error) => 
          {
            //loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
      },deleteData(id){
          let loader= this.$loading.show();
          axios.delete(`${this.baseUrl}/Homework/Delete/`+id, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            this.loadData();
            loader.hide();
            this.$swal("",response.data.message, "success");
          })
          .catch((error) => 
          {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
      },deleteFile(id){
            let loader= this.$loading.show();
            axios.delete(this.baseUrl+'/Homework/File/Delete/'+id, {
                headers: { Authorization: `Bearer ${this.user.jwt}`}
            }).then((response) => 
            {
             loader.hide();
             this.$swal("",response.data.message, "success");
             this.initialData(this.id);
            })
            .catch((error) => 
            {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
            });
      },deleteConfirm(id,type){
        this.$swal({ 
          title: "WARNING",
          text: "Are you sure? Will be deleted along with the connected data.",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes",
          cancelButtonText:"No",
          }).then((result) => {
            if (result.value) 
            {
              if(type==0)
              this.deleteData(id)
              else if(type==1)
              this.deleteFile(id)
            }
          });
      },showModal(modalid) {
        this.$root.$emit('bv::show::modal',modalid)
      },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
     }
  };
</script>
<style>
.cover-image{
  width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
        <div class="col-lg-12">
          <a type="button" id="add-modal"   v-b-modal.modal-lg  class="btn btn-success" @click="getMyGroups()"><i class="fa fa-cogs"></i> + ADD NEW WORK</a>
        </div>
    </div>
    <div class="row" id="categories-div">
        <div class="col-md-4" v-for="(item, index) in files" :key="index">
            <div class="card">
              <div class="card-header bg-info text-white">
                <p class="text-truncate font-size-14 mb-2"><i class="fa fa-folder"></i> {{item.name}} <i 
                              v-if="(item.groupId!=null && item.groupId!='')" class="fa fa-users float-right" v-b-tooltip.hover :title="item.group.name"></i></p>
              </div>
                    <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-1 overflow-hidden" style="width:100%">
                          <p><i class="fa fa-user"></i> {{ item.user.fullName }}</p>
                
                            <p class="text-truncate font-size-14 mb-2"><i class="fa fa-calendar"></i> {{ format_date(item.createDate)}}</p>
                            <p class="text-truncate font-size-14 mb-2">{{item.explanation}}</p>
                        </div>
                    </div>
                </div>
                <div class="card-body border-top py-3">
                    <div class="text-truncate">
                        <router-link :to="{ path: 'homeworkdetail/'+item.id}" tag="a" v-b-tooltip.hover title="Files" class="btn btn-sm btn-info"><i class="fa fa-file"></i> {{item.homeworkFiles.length}}</router-link>
                        <router-link :to="{ path: 'homeworkdetail/'+item.id}" tag="a" v-b-tooltip.hover title="Detail" class="btn btn-sm btn-info float-right ml-1"><i class="fa fa-eye"></i></router-link>
                        <a type="button" v-if="user.rol=='admin' | user.id==item.user.id" v-b-tooltip.hover title="Delete" class="btn btn-sm btn-danger float-right ml-1"  @click="deleteConfirm(item.id,0)"><i class="fa fa-trash"></i></a>
                        <a type="button" v-if="user.rol=='admin' | user.id==item.user.id"  v-b-modal.modal-edit  v-b-tooltip.hover title="Edit" class="btn btn-sm btn-warning float-right ml-1"  @click="initialData(item.id)"><i class="fa fa-edit"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-lg" size="lg" title="New Work Item" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-file-form">
            
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Work Name (*) <i class="fa fa-info-circle"  v-b-tooltip.hover title="The title of the homework can never be changed."></i></label>
                        <input type="text" class="form-control" name="name" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Group Name (*)</label>
                        <select name="groupId" id="groupId" v-model="groupId"  class="form-control" @change="selectedGroup($event)">
                        <option value="">--Select Group--</option>
                        <option :value="item.id" v-for="item in myGorups" :key="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                      <label id="tooltip-video-link"><i class="fa fa-info-circle"></i> Video Link</label>
                      <b-tooltip target="tooltip-video-link" triggers="hover">
                        <img src="@/assets/images/video-link-example.jpg" style="width:100%" />
                        <p>
                          Copy the content of the src link in the video sharing link.
                        </p>
                      </b-tooltip>
                        <input type="text" class="form-control" name="videoLink" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                      <label id="tooltip-link"><i class="fa fa-info-circle"></i> Link</label>
                      <b-tooltip target="tooltip-link" triggers="hover">
                        <img src="@/assets/images/link-example.jpg" style="width:100%" />
                        <p>
                          Copy the place in the browser link section.
                        </p>
                      </b-tooltip>
                        <input type="text" class="form-control" name="link" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                    <div class="form-group">
                        <label>HomeWork Name (*)</label>
                        <select name="reminderId" id="reminderId" v-model="reminderId" class="form-control" disabled>
                        <option value="">--Select Work Category--</option>
                        <option :value="item.id" v-for="item in remiders" :key="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Cover Image</label>
                        <input type="file" class="form-control" name="file" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE WORK</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-edit" size="lg" title="Edit Work Item" title-class="font-18" hide-footer>
        <form @submit.prevent="editData()" id="edit-file-form">
            
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                      <label>Work Name (*) <i class="fa fa-info-circle"  v-b-tooltip.hover title="The title of the homework can never be changed."></i></label>
                        <input type="text" class="form-control" name="name" v-model="name" autocomplete="off" readonly/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Group Name (*)</label>
                        <select name="groupId" id="groupId" v-model="groupId" class="form-control">
                        <option value="">--Select Group--</option>
                        <option :value="item.id" v-for="item in myGorups" :key="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                      <label id="tooltip-video-link"><i class="fa fa-info-circle"></i> Video Link</label>
                      <b-tooltip target="tooltip-video-link" triggers="hover">
                        <img src="@/assets/images/video-link-example.jpg" style="width:100%" />
                        <p>
                          Copy the content of the src link in the video sharing link.
                        </p>
                      </b-tooltip>
                        <input type="text" class="form-control" name="videoLink" v-model="videoLink" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                      <label id="tooltip-link"><i class="fa fa-info-circle"></i> Link</label>
                      <b-tooltip target="tooltip-link" triggers="hover">
                        <img src="@/assets/images/link-example.jpg" style="width:100%" />
                        <p>
                          Copy the place in the browser link section.
                        </p>
                      </b-tooltip>
                        <input type="text" class="form-control" name="link" v-model="link" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Cover Image</label>
                       
                        <input type="file" class="form-control" name="file" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>HomeWork Name (*)</label>
                        <select name="reminderId" id="reminderId" v-model="reminderId" class="form-control" disabled>
                        <option value="">--Select Work Category--</option>
                        <option :value="item.id" v-for="item in remiders" :key="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
          
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" v-if="homeworkimageUrl!=null && homeworkimageUrl!=''">
                <div class="card">
                                <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-1 overflow-hidden" style="width:100%">
                                      <img id="file" :src="homeworkimageUrl" class="cover-image" />
                                    </div>
                                </div>
                            </div>
                            <div class="card-body border-top py-3">
                                <div class="text-truncate">
                                  <a type="button"  class="badge badge-info font-size-11"  v-b-tooltip.hover title="Cover image cannot be deleted. However, it can be changed."><i class="fa fa-info-circle"></i> Cover ımage</a>
                                </div>
                            </div>
                        </div>
              </div>
              
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" v-for="(item, index) in detail" :key="index">
                <div class="card">
                        <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-1 overflow-hidden" style="width:100%">
                                <img v-if="(item.fileExtension=='.jpg'| item.fileExtension=='.JPG'| item.fileExtension=='.png' | item.fileExtension=='.PNG')" :src="fileUrl+'/files/images/homework/'+item.basePath+'/'+item.subPath+'/files/'+item.name" class="cover-image" />
                                <img v-if="(item.fileExtension=='.pdf')" :src="fileUrl+'/files/images/pdf.png'" class="cover-image" />
                                <img v-if="(item.fileExtension=='.xlsx')" :src="fileUrl+'/files/images/excel.png'" class="cover-image" />
                                <img v-if="(item.fileExtension=='.docx')" :src="fileUrl+'/files/images/word.png'" class="cover-image" />
                            </div>
                        </div>
                    </div>
                    <div class="card-body border-top py-3">
                        <div class="text-truncate">
                            <a type="button"  class="badge badge-danger font-size-11" @click="deleteConfirm(item.id,1)"><i class="fa fa-trash"></i> Delete</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
            <div class="row">
                <div class="col-sm-12">
                    <vue-dropzone ref="FileDropzone" v-model="files" name="files" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" class="form-control" v-model="explanation"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE WORK</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>